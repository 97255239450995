<nb-layout>
  <nb-layout-column class="w-100 wrapper p-0">
    <div class="main">
      <div class="main__container d-flex">
        <div class="side-nav">
          <a
            href="https://tinproxy.com/dashboard"
            class="d-flex align-items-center side-nav__link"
          >
            <img src="/assets/images/logo.svg" />
            <span class="d-block d-lg-none text-white text-lg ml-3">
              TinProxy
            </span>
          </a>

          <div class="side-nav__devider my-6"></div>
          <ul>
            <li>
              <a href="https://tinproxy.com/dashboard" class="side-menu">
                <div class="side-menu__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-wifi"
                  >
                    <path d="M5 12.55a11 11 0 0 1 14.08 0"></path>
                    <path d="M1.42 9a16 16 0 0 1 21.16 0"></path>
                    <path d="M8.53 16.11a6 6 0 0 1 6.95 0"></path>
                    <line x1="12" y1="20" x2="12.01" y2="20"></line>
                  </svg>
                </div>
                <div class="side-menu__title d-block d-lg-none">Proxy Việt Nam</div>
              </a>
            </li>
            <li>
              <a href="https://tinproxy.com/account" class="side-menu">
                <div class="side-menu__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-user-check"
                  >
                    <path d="M16 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                    <circle cx="8.5" cy="7" r="4"></circle>
                    <polyline points="17 11 19 13 23 9"></polyline>
                  </svg>
                </div>
                <div class="side-menu__title d-block d-lg-none">Tài khoản</div>
              </a>
            </li>
            <li>
              <a href="https://tinproxy.com/account/recharge" class="side-menu">
                <div class="side-menu__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-dollar-sign"
                  >
                    <line x1="12" y1="1" x2="12" y2="23"></line>
                    <path
                      d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"
                    ></path>
                  </svg>
                </div>
                <div class="side-menu__title d-block d-lg-none">Nạp tiền</div>
              </a>
            </li>
            <li>
              <a href="https://tinproxy.com/affiliate" class="side-menu">
                <div class="side-menu__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-share-2"
                  >
                    <circle cx="18" cy="5" r="3"></circle>
                    <circle cx="6" cy="12" r="3"></circle>
                    <circle cx="18" cy="19" r="3"></circle>
                    <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
                    <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
                  </svg>
                </div>
                <div class="side-menu__title d-block d-lg-none">Cộng tác viên</div>
              </a>
            </li>
            <li class="side-nav__devider my-6"></li>
            <li>
              <a href="https://tinproxy.com/document" class="side-menu">
                <div class="side-menu__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-file-text"
                  >
                    <path
                      d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"
                    ></path>
                    <polyline points="14 2 14 8 20 8"></polyline>
                    <line x1="16" y1="13" x2="8" y2="13"></line>
                    <line x1="16" y1="17" x2="8" y2="17"></line>
                    <polyline points="10 9 9 9 8 9"></polyline>
                  </svg>
                </div>
                <div class="side-menu__title d-block d-lg-none">API Document</div>
              </a>
            </li>
            <li class="side-nav__devider my-6"></li>
            <li>
              <a href="https://tinproxy.com/tool" class="side-menu">
                <div class="side-menu__icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-box"
                  >
                    <path
                      d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"
                    ></path>
                    <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline>
                    <line x1="12" y1="22.08" x2="12" y2="12"></line>
                  </svg>
                </div>
                <div class="side-menu__title d-block d-lg-none">Tool</div>
              </a>
            </li>
          </ul>
        </div>
        <div class="content">
          <div class="header d-flex justify-content-between align-items-center">
            <a href="">
              TinProxy - Hệ Thống Cung Cấp Proxy Dân Cư Rotating Hàng Đầu Việt
              Nam</a
            >
            <div class="user">
              <a nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
                <div class="image">
                  <img src="/assets/images/avatar.png" class="w-100" />
                </div>
              </a>
              <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                  <li nz-menu-item style="background-color: transparent">
                    <div class="account">
                      <p class="m-0">Tài khoản</p>
                      <span>TinProxy System</span>
                    </div>
                  </li>
                  <li nz-menu-divider class="p-0"></li>
                  <li nz-menu-item>
                    <i class="fa-regular fa-user"></i>Thông tin cá nhân
                  </li>
                  <li nz-menu-item>
                    <span nz-icon nzType="lock" nzTheme="outline"></span>Thay
                    đổi mật khẩu
                  </li>
                  <li nz-menu-item>
                    <span
                      nz-icon
                      nzType="question-circle"
                      nzTheme="outline"
                    ></span
                    >Hỗ trợ
                  </li>
                  <li nz-menu-divider class="p-0"></li>
                  <li nz-menu-item>
                    <i class="fa-solid fa-toggle-off"></i>Đăng xuất
                  </li>
                </ul>
              </nz-dropdown-menu>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nb-layout-column>
</nb-layout>
