export const environment = {
  production: false,
  siteTitle: 'TinProxy - Hệ Thống Cung Cấp Proxy Dân Cư Rotating Hàng Đầu Việt Nam',
  image: 'https://i.imgur.com/pE9ZTVF.jpg',
  enableSentry: false,
  sentryDsn: '',
  sentryEnvironment: 'dev',
  tracesSampleRate: 0.1,
  enableMockApi: false,
  tokenKey: 'token',
  // apiUrl: 'http://localhost:8083/api',
  apiUrl: 'https://api.tinproxy.vn/api',
  languageLocalStorageKey: 'lang',
  availabelLanguages: ['vi', 'en'],
  defaultLanguage: 'vi',
  fbMessengerEnable: true,
};